<template>
  <div>

    <el-divider></el-divider>

    <!-- 路由信息 -->
    <el-form :inline="true"
    v-model="info"
    label-width="100px"
    label-position="right">
        <el-row>

            <el-col :span="24">
                <el-form-item label="员工姓名">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>员工姓名</span>
                    </template>
                    <el-select v-model="info.userNumber" filterable class="search-200">
                        <el-option v-for="info in selectUserList" :key="info.userNumber" :label="info.userName" :value="info.userNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <!-- <el-col :span="12">
                <el-form-item label="单据类型">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>单据类型</span>
                    </template>
                    <el-select v-model="info.billType">
                        <el-option label="采购单" :value="1"></el-option>
                        <el-option label="销售单" :value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-col> -->

            <el-col :span="24">
                <el-form-item label="限制天数">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>限制天数</span>
                    </template>
                    <el-input-number v-model="info.limitDay" :min="2" :max="30" class="search-200"></el-input-number>
                    <!-- 提示 -->
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="用来控制在制单时可用的天数。最少2天"
                        placement="top"
                    >
                        <i class="iconfont icon-wenhao"></i>
                    </el-tooltip>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="库存价格">
                    <el-radio-group v-model="info.stockPrice">
                        <el-radio-button label="隐藏" :value="0"></el-radio-button>
                        <el-radio-button label="显示" :value="1"></el-radio-button>
                    </el-radio-group>
                    <!-- 提示 -->
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="用来控制在库存界面是否显示成本单价、成本总价。"
                        placement="top"
                    >
                        <i class="iconfont icon-wenhao"></i>
                    </el-tooltip>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="默认打印机">
                    <el-select v-model="info.defPrinter" class="search-200">
                        <el-option value="0" label="无默认打印机"></el-option>
                        <el-option v-for="printer in printerList" :key="printer.id" :label="printer.printerName" :value="printer.printerNumber"></el-option>
                    </el-select>
                    <!-- 提示 -->
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="用来控制用户在使用打印机时，优先使用默认设置。"
                        placement="top"
                    >
                        <i class="iconfont icon-wenhao"></i>
                    </el-tooltip>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>


    <div class="dataSource">
        提示：设置完成后，刷新页面才能生效。
    </div>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData(2)">保存并新增</el-button>
        <el-button type="primary"  @click="btnSaveData(1)">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import AllUserList from '@/utils/mixins/AllUserList.js'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    mixins:[AllUserList],
    data(){
        return{
            intId:this.editId,  //编号
            info:{
                id:0,
                userNumber:'',
                userName:'',
                billType:0,
                limitDay:2,
                stockPrice:0,  //是否显示库存价格 0:否 1:是
                defPrinter:'0',  //默认打印机设置
            },
            printerList:[],  //打印机列表
        }
    },
    methods:{

        //加载小票打印机列表
        btnLoadPrinterList(){
            this.$api.system.SelectPrinterList().then(res=>{
                if(res.data.status === 200){
                    this.printerList = res.data.data;
                    //默认项
                    if(this.printerList !=null && this.printerList.length>0){
                        this.info.defPrinter = this.printerList[0].printerNumber;
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //加载数据
        GetDataInfo(){
            if(this.intId !=0){
                let param = {
                    id:this.intId
                }
                this.$api.common.GetSetUser(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存数据
        btnSaveData(type){
            this.$api.common.SaveSetUser(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    if(type==1){
                        this.btnClose();
                    }else{
                        this.btnClearData();
                    }
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.id === this.intId
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.intId = prevInfo.id
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.id === this.intId
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.intId = prevInfo.id
                this.GetDataInfo()
            }
        },

        //清空数据
        btnClearData(){
            this.info = {
                id:0,
                userNumber:'',
                userName:'',
                billType:1,
                limitDay:2,
                stockPrice:0,  //是否显示库存价格 0:否 1:是
                DefPrinter:'0',  //默认打印机
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.btnLoadPrinterList();  //打印机列表
        this.GetDataInfo();
    },
}
</script>

<style scoped>
.dataSource{margin-left: 30px;font-size: 14px;color: #ccc;}
</style>