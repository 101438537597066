<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
      <el-col :span="24">
        <el-form :inline="true">

          <el-form-item label="编号">
            <el-input v-model="info.search.knowledgeNumber" :maxlength="20" class="search-150"></el-input>
          </el-form-item>

          <el-form-item label="标题">
            <el-input v-model="info.search.title" :maxlength="20" class="search-150"></el-input>
          </el-form-item>

          <el-form-item label="类型">
            <el-tree-select v-model="info.search.typeId" :data="knowledgeTypeList" :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
            </el-tree-select>
          </el-form-item>

          <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
          </el-form-item>

        </el-form>
      </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnKnowledgeAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-button v-if="CheckUserButtonAuth('BtnKnowledgeDelete')" class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
                
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
          <el-table-column type="selection" prop="knowledgeNumber" width="50" />  
          <el-table-column prop="knowledgeNumber" label="编号" width="170" />
        
          <el-table-column prop="typeName" label="知识类型" min-width="100"></el-table-column>
          <el-table-column prop="title" label="知识标题" min-width="150">
              <template v-slot="scope">
                  <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="scope.row.title"
                  placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.title}}</div>
                  </el-tooltip>
              </template>
          </el-table-column>        

          <el-table-column label="创建日期" width="110">
              <template #default="scope">
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="scope.row.createTime"
                      placement="top"
                  >
                  <span>{{DateFormat(scope.row.createTime)}}</span>
                  </el-tooltip>
              </template>
          </el-table-column>

          <el-table-column label="创建者" width="110" prop="createName">
          </el-table-column>

          <el-table-column label="操作" width="130" fixed="right">
              <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.knowledgeNumber)">详情</el-button>
                <el-button v-if="CheckUserButtonAuth('BtnKnowledgeDelete')" size="small" type="danger" @click="btnDeleteInfo(scope.row.knowledgeNumber)">删除</el-button>
             
              </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500, 1000]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange">
            </el-pagination>
        </el-container>
    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="700px"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditKnowledge :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditKnowledge>
    </el-dialog>

  </div>
</template>

<script>
import EditKnowledge from '@/views/Oa/Knowledge/Info/EditKnowledge.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  components:{
    EditKnowledge
  },
  data(){
    return{
      dataList:[],  //Table数据
      info:{
        page:1,
        size:50,
        total:0,  //总记录数
        id:0,  //新增、编辑 的参数
        ids:[],   //删除 的参数
        search:{
          knowledgeNumber:'',
          typeId:0,
          title:'',
        }
      },
      knowledgeTypeList:[],   //知识类型列表
      //编辑-弹出框
      BoxEditInfo:{
        isVisible:false,
        title:'编辑知识'
      },
    }
  },
  computed:{
      //自适应窗体高度
      GetDataHeight(){
          return window.innerHeight-180
      }
  },
  methods:{
    //获取知识类型列表
    GetKnowledgeTypeList(){
      this.$api.oa.SelectTreeKnowledgeTypeList().then(res=>{
        if(res.data.status === 200){
          this.knowledgeTypeList=res.data.data;
        }else{
          this.knowledgeTypeList=[];
        }
      })
    },

    //加载数据列表
    GetDataList(){
      let param = {
        knowledgeNumber:this.info.search.knowledgeNumber,
        typeId:this.info.search.typeId,
        title:this.info.search.title,
        page:this.info.page,
        size:this.info.size
      }
      this.$api.oa.PageKnowledgeList(param).then(res=>{
        if(res.data.status === 200){
          this.dataList = res.data.data;
          //分页
          this.info.total = res.data.total
          this.info.page = res.data.pageNumber
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.knowledgeNumber)
    },
    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
    },

    //新增编辑部门信息
    btnEditInfo(knowledgeNumber){
        this.info.id = knowledgeNumber
        if(knowledgeNumber==0){
            this.BoxEditInfo.title = "新增知识"
        }else{
            this.BoxEditInfo.title = "编辑知识"
        }
        this.BoxEditInfo.isVisible = true;
    },
    //关闭弹出框
    btnCloseBox(){
        this.BoxEditInfo.isVisible = false;
    },

    //删除按钮
    btnDeleteInfo(knowledgeNumber){
        ElMessageBox.confirm('确定要删除该项吗?','删除提示',{
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(()=>{
            let param = [knowledgeNumber]
            this.$api.oa.DeleteKnowledge(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        }).catch(()=>{
            //取消
        })
        
    },
    //批量删除
    btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
        }

        ElMessageBox.confirm('确定要删除该项吗?','删除提示',{
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(()=>{
            this.$api.oa.DeleteKnowledge(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList();
                    this.info.ids = [];
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        }).catch(()=>{
            //取消
        })        
    },

    //重置按钮
    btnResetSearch(){
        this.info.search.knowledgeNumber='';
        this.info.search.typeId =0;
        this.info.search.title='';
        this.info.ids = [];  //编号数组
        this.info.page = 1;
        this.GetDataList();
    }, 


  },
  mounted(){
    this.GetKnowledgeTypeList();
    this.GetDataList();   //加载数据
  },

}
</script>

<style>

</style>