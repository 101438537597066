<template>
  <div>
    <!-- 盘点信息 -->

    <el-descriptions
    :column="1"
    border>
        <el-descriptions-item label="仓库">
            {{info.warehouseName}}
        </el-descriptions-item>

        <el-descriptions-item label="产品分类">
            {{info.cidName}}
        </el-descriptions-item>

        <el-descriptions-item label="品牌">
            {{info.brandName}}
        </el-descriptions-item>

        <el-descriptions-item label="产品名称">
            {{info.spuName}} {{info.skuName}}
        </el-descriptions-item>

        <el-descriptions-item label="单位">
            <el-select v-model="info.unitId" class="search-200">
                <el-option v-for="skuUnit in info.skuUnitList" :key="skuUnit.id" :label="skuUnit.unitName" :value="skuUnit.unitId"></el-option>
            </el-select>
        </el-descriptions-item>

        <el-descriptions-item label="盘点数量">
            <el-input-number v-model="info.checkNum" :min="0" :controls="false" class="search-200"></el-input-number>
        </el-descriptions-item>

        <el-descriptions-item label="盘点状态">
            <el-tag v-if="info.checkState == 0" type="warning">未盘点</el-tag>
            <el-tag v-if="info.checkState == 1" type="danger">待复盘</el-tag>
            <el-tag v-if="info.checkState == 2" type="info">已盘点</el-tag>
        </el-descriptions-item>

        <el-descriptions-item label="提示">
            <el-button v-if="info.lockStockFlag == 1" type="danger" circle size="small" >锁</el-button>
        </el-descriptions-item>

    </el-descriptions>

    <div class="btnGroup">
        <el-button @click="btnClose">关闭</el-button>   
        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button v-if="checkInfo.info && (checkInfo.info.state==2 || checkInfo.info.state ==4)" @click="btnSaveCheckDetail" type="primary">提交</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
    props:['editId'],
    data(){
        return{
            info:{
                id:0,
                warehouseNumber:'',
                warehouseName:'',
                cid:0,
                cidName:'',
                brandId:0,
                brandName:'',
                spuNumber:'',
                spuName:'',
                skuNumber:'',
                skuName:'',
                unitId:0,
                checkNumber:'',
                checkNum:0,
                checkState:0,
                skuUnitList:[],   //单位列表
            },
            oldInfo:{},
            intId:this.editId,
        }
    },
    computed:{
        ...mapState('Erp',['checkInfo']),
    },
    methods:{
        //加载数据
        GetDataInfo(){
            this.checkInfo.detailList.forEach(item=>{
                if(item.id == this.intId){
                    this.info = item;
                }
            })
        },

        //保存单个盘点
        btnSaveCheckDetail(){
            this.$api.wms.UpdateCheckDetail(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnNext();
                }else{
                    ElMessage.error(res.data.msg);
                    return false
                }
            })         
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.checkInfo.detailList.findIndex((item)=>{
                return item.id === this.intId
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.checkInfo.detailList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.intId = prevInfo.id
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.checkInfo.detailList.findIndex((item)=>{
                return item.id === this.intId
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.checkInfo.detailList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.intId = prevInfo.id
                this.GetDataInfo()
            }
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style>

</style>