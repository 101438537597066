
//合同管理
import ListContract from '@/views/Oa/Contract/ListContract.vue'
//指纹收录
import ListBiology from '@/views/Oa/Biology/ListBiology.vue'
//年假调整记录
import ListAnnualLeave from '@/views/Oa/AnnualLeave/ListAnnualLeave.vue'

//班次设置
import RuleClasses from '@/views/Oa/Classes/ListClasses.vue'
//常班设置
import RuleWork from '@/views/Oa/Rule/RuleWork.vue'
//排班表
import ListSchedule from '@/views/Oa/Schedule/ListSchedule.vue'

//打卡记录
import ListRecord from '@/views/Oa/ClockIn/ListRecord.vue'
//补卡记录
import ListRepair from '@/views/Oa/ClockIn/ListRepair.vue'
//出差记录
//请假记录
//考勤汇总


//工资单结构项设置
import ListWageItem from '@/views/Oa/Wage/ListWageItem.vue'
//工资单初始项设置
import ListWageInitial from '@/views/Oa/Wage/ListWageInitial.vue'
//工资单
import ListWage from '@/views/Oa/Wage/ListWage.vue'

//知识管理
import ListKnowledgeType from '@/views/Oa/Knowledge/Type/ListKnowledgeType.vue'
import ListKnowledge from '@/views/Oa/Knowledge/Info/ListKnowledge.vue'
//审批管理
import ListProcessType from '@/views/Oa/Process/Type/ListProcessType.vue'
import ListProcess from '@/views/Oa/Process/Info/ListProcess.vue'
//活动管理
import ListActivityType from '@/views/Oa/Activity/Type/ListActivityType.vue'
//凭证管理
import ListProof from '@/views/Oa/Proof/Info/ListProof.vue'
import ListProofType from '@/views/Oa/Proof/Type/ListProofType.vue'
//导出列表
import ListExport from '@/views/Oa/Export/ListExport.vue'

export default[
    {
        //合同管理
        name:'ListContract',
        path:'listcontract',
        component:ListContract
    },

    {
        //指纹收录
        name:'ListBiology',
        path:'listbiology',
        component:ListBiology
    },
    {
        //年假调整记录
        name:'ListAnnualLeave',
        path:'listannualleave',
        component:ListAnnualLeave
    },
    {
        //班次设置
        name:'RuleClasses',
        path:'ruleclasses',
        component:RuleClasses
    },

    {
        //常班设置
        name:'RuleWork',
        path:'rulework',
        component:RuleWork
    },
    {
        //排班设置
        name:'ListSchedule',
        path:'listschedule',
        component:ListSchedule
    },

    {
        //打卡记录
        name:'ListRecord',
        path:'listrecord',
        component:ListRecord
    },
    {
        //补卡记录
        name:'ListRepair',
        path:'listrepair',
        component:ListRepair
    },

    {
        //工资单结构项设置
        name:'ListWageItem',
        path:'listwageitem',
        component:ListWageItem
    },
    {
        //工资单初始项设置
        name:'ListWageInitial',
        path:'listwageinitial',
        component:ListWageInitial
    },
    {
        //工资单
        name:'ListWage',
        path:'listwage',
        component:ListWage
    },

    {
        //知识类型
        name:'ListKnowledgeType',
        path:'listknowledgetype',
        component:ListKnowledgeType
    },
    {
        //知识管理
        name:'ListKnowledge',
        path:'listknowledge',
        component:ListKnowledge
    },
    {
        //审批类型
        name:'ListProcessType',
        path:'listprocesstype',
        component:ListProcessType
    },
    {
        //审批单
        name:'ListProcess',
        path:'listprocess',
        component:ListProcess
    },
    {
        //活动类型
        name:'ListActivityType',
        path:'listactivitytype',
        component:ListActivityType
    },
    {
        //凭证管理
        name:'ListProof',
        path:'listproof',
        component:ListProof
    },
    {
        //凭证类型
        name:'ListProofType',
        path:'listprooftype',
        component:ListProofType
    },
    {
        //导出列表
        name:'ListExport',
        path:'listexport',
        component:ListExport
    },

]

