/**
 * OA办公模块接口
 * 
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const oa = {

    //#region 班次设置

    //获取班次设置列表
    ListRuleClasses(param){
        return axios.get(`${base.base}/Classes/ListRuleClasses`,{
            params:param
        })
    },

    //获取单个班次设置信息
    SingleRuleClasses(param){
        return axios.get(`${base.base}/Classes/SingleRuleClasses`,{
            params:param
        })
    },

    //保存班次设置
    SaveRuleClasses(param){
        return axios.post(`${base.base}/Classes/SaveRuleClasses`,param)
    },

    //删除班次设置
    DeleteRuleClasses(param){
        return axios.post(`${base.base}/Classes/DeleteRuleClasses`,param)
    },

    //下拉列表-获取班次
    SelectClassesList(){
        return axios.get(`${base.base}/Classes/SelectClassesList`)
    },

    //#endregion

    //#region 常班设置 - 周设置

    //列表-按周设置
    ListRuleWorkWeek(param){
        return axios.get(`${base.base}/Rule/ListRuleWorkWeek`,{
            params:param
        })
    },

    //单个信息-按周设置
    SingleRuleWorkWeek(){
        return axios.get(`${base.base}/Rule/SingleRuleWorkWeek`)
    },

    //保存-按周设置
    SaveRuleWorkWeek(param){
        return axios.post(`${base.base}/Rule/SaveRuleWorkWeek`,param)
    },

    //删除-按周设置
    DeleteRuleWorkWeek(){
        return axios.post(`${base.base}/Rule/DeleteRuleWorkWeek`)
    },

    //#endregion

    //#region 常班设置-按日设置

    //列表-按日设置
    ListRuleWorkDay(param){
        return axios.post(`${base.base}/Rule/ListRuleWorkDay`,param)
    },

    //单个信息-按日设置
    SingleRuleWorkDay(param){
        return axios.get(`${base.base}/Rule/SingleRuleWorkDay`,{
            params:param
        })
    },

    //保存
    SaveRuleWorkDay(param){
        return axios.post(`${base.base}/Rule/SaveRuleWorkDay`,param)
    },

    //删除
    DeleteRuleWorkDay(param){
        return axios.post(`${base.base}/Rule/DeleteRuleWorkDay`,param)
    },

    //#endregion

    //#region 常班设置-工作表

    //获取指定月的工作表
    ListWorkSheet(param){
        return axios.get(`${base.base}/Rule/ListWorkSheet`,{
            params:param
        })
    },

    //保存指定月的工作表
    BatchSaveWorkSheet(param){
        return axios({
            url:`${base.base}/Rule/BatchSaveWorkSheet`,
            method:'Post',
            params:param
        })
    },

    //#endregion

    //#region 排班设置

    //获取排班列表
    ListSchedule(param){
        return axios.post(`${base.base}/Schedule/ListSchedule`,param)
    },

    //批量保存排班记录
    BatchSaveSchedule(param){
        return axios.post(`${base.base}/Schedule/BatchSaveSchedule`,param)
    },

    //删除排班记录
    DeleteSchedule(param){
        return axios.post(`${base.base}/Schedule/DeleteSchedule`,param)
    },

    //获取单日排班记录
    GetSingleDayScheduleList(param){
        return axios.get(`${base.base}/Schedule/GetSingleDayScheduleList`,{
            params:param
        })
    },

    //获取单月排班记录
    GetSingleMonthScheduleList(param){
        return axios.get(`${base.base}/Schedule/GetSingleMonthScheduleList`,{
            params:param
        })
    },

    //#endregion

    //#region 假期管理

    //获取用户假期设置列表
    ListLeave(param){
        return axios.get(`${base.base}/Leave/ListLeave`,{
            params:param
        })
    },

    //#endregion

    //#region 外出公干
    //#endregion

    //#region 资产管理
    //#endregion

    //#region 规则设置
    //#endregion

    //#region 打卡记录
    
    //#endregion

    //#region 薪酬结构设置

    //列表
    PageWageItemList(param){
        return axios.post(`${base.base}/Wage/PageWageItemList`,param)
    },

    //获取单个信息
    SingleWageItem(param){
        return axios.get(`${base.base}/Wage/SingleWageItem`,{
            params:param
        })
    },

    //保存
    SaveWageItem(param){
        return axios.post(`${base.base}/Wage/SaveWageItem`,param)
    },

    //删除
    DeleteWageItem(param){
        return axios.post(`${base.base}/Wage/DeleteWageItem`,param)
    },

    //下拉列表
    SelectWageItemList(){
        return axios.get(`${base.base}/Wage/SelectWageItemList`)
    },

    //#endregion

    //#region 工资单初始项设置

    //列表
    ListWageInitial(param){
        return axios.get(`${base.base}/Wage/ListWageInitial`,{
            params:param
        })
    },

    //获取单个信息
    SingleWageInitial(param){
        return axios.get(`${base.base}/Wage/SingleWageInitial`,{
            params:param
        })
    },

    //保存
    SaveWageInitial(param){
        return axios.post(`${base.base}/Wage/SaveWageInitial`,param)
    },

    //删除
    DeleteWageInitial(param){
        return axios.post(`${base.base}/Wage/DeleteWageInitial`,param)
    },


    //#endregion
    
    //#region 工资单

    //列表
    PageWageList(param){
        return axios.post(`${base.base}/Wage/PageWageList`,param)
    },

    //获取单个工资单(所有信息)
    SingleWageAllInfo(param){
        return axios.get(`${base.base}/Wage/SingleWageAllInfo`,{
            params:param
        })
    },

    //批量保存工资单
    SaveBatchWage(param){
        return axios.post(`${base.base}/Wage/SaveBatchWage`,param)
    },

    //单个保存工资单
    SaveWage(param){
        return axios.post(`${base.base}/Wage/SaveWage`,param)
    },


    //获取工资单明细列表
    ListWageDetail(param){
        return axios.get(`${base.base}/Wage/ListWageDetail`,{
            params:param
        })
    },

    //删除工资单
    DeleteWageAllInfo(param){
        return axios.post(`${base.base}/Wage/DeleteWageAllInfo`,param)
    },

    //更新工资单状态
    UpdateWageState(param){
        return axios.post(`${base.base}/Wage/UpdateWageState`,param)
    },

    //工资单生成财务单
    CreateFinance(param){
        return axios.post(`${base.base}/Wage/CreateFinance`,param)
    },

    //导出工资单
    ExportWage(param){
        return axios.post(`${base.base}/Wage/ExportWage`,param)
    },

    //#endregion

    //#region 知识类型

    //获取知识类型列表
    ListKnowledgeType(param){
        return axios.post(`${base.base}/Knowledge/ListKnowledgeType`,param)
    },

    //获取单个知识类型
    SingleKnowledgeType(param){
        return axios.get(`${base.base}/Knowledge/SingleKnowledgeType`,{
            params:param
        })
    },

    //保存知识类型
    SaveKnowledgeType(param){
        return axios.post(`${base.base}/Knowledge/SaveKnowledgeType`,param)
    },

    //删除知识类型
    DeleteKnowledgeType(param){
        return axios.post(`${base.base}/Knowledge/DeleteKnowledgeType`,param)
    },

    //获取知识类型-目录树
    SelectTreeKnowledgeTypeList(){
        return axios.get(`${base.base}/Knowledge/SelectTreeKnowledgeTypeList`)
    },

    //#endregion

    //#region 知识管理

    //列表-分页
    PageKnowledgeList(param){
        return axios.post(`${base.base}/Knowledge/PageKnowledgeList`,param)
    },

    //单个
    GetKnowledgeAllInfo(param){
        return axios.get(`${base.base}/Knowledge/GetKnowledgeAllInfo`,{
            params:param
        })
    },

    //保存
    SaveKnowledgeAllInfo(param){
        return axios.post(`${base.base}/Knowledge/SaveKnowledgeAllInfo`,param)
    },

    //批量删除
    DeleteKnowledge(param){
        return axios.post(`${base.base}/Knowledge/DeleteKnowledge`,param)
    },

    //删除单个知识文件
    DeleteKnowledgeFile(param){
        return axios({
            url:`${base.base}/Knowledge/DeleteKnowledgeFile`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 审批类型

    //获取审批类型列表
    ListProcessType(param){
        return axios.post(`${base.base}/Process/ListProcessType`,param)
    },

    //获取单个审批类型
    SingleProcessType(param){
        return axios.get(`${base.base}/Process/SingleProcessType`,{
            params:param
        })
    },

    //保存审批类型
    SaveProcessType(param){
        return axios.post(`${base.base}/Process/SaveProcessType`,param)
    },

    //删除审批类型
    DeleteProcessType(param){
        return axios.post(`${base.base}/Process/DeleteProcessType`,param)
    },

    //获取审批类型-目录树
    SelectTreeProcessTypeList(){
        return axios.get(`${base.base}/Process/SelectTreeProcessTypeList`)
    },

    //#endregion
    
    //#region 审批单

    //列表
    PageProcessList(param){
        return axios.post(`${base.base}/Process/PageProcessList`,param)
    },

    //获取单个信息
    SingleProcessAllInfo(param){
        return axios.get(`${base.base}/Process/SingleProcessAllInfo`,{
            params:param
        })
    },

    //保存
    SaveProcessAllInfo(param){
        return axios.post(`${base.base}/Process/SaveProcessAllInfo`,param)
    },

    //删除
    DeleteProcessAllInfo(param){
        return axios.post(`${base.base}/Process/DeleteProcessAllInfo`,param)
    },

    //更新状态
    UpdateProcessState(param){
        return axios.post(`${base.base}/Process/UpdateProcessState`,param)
    },


    //#endregion

    //#region 活动类型

    //获取活动类型
    ListActivityType(param){
        return axios.post(`${base.base}/Activity/ListActivityType`,param)
    },

    //获取单个活动类型
    SingleActivityType(param){
        return axios.get(`${base.base}/Activity/SingleActivityType`,{
            params:param
        })
    },

    //保存活动类型
    SaveActivityType(param){
        return axios.post(`${base.base}/Activity/SaveActivityType`,param)
    },

    //删除活动类型
    DeleteActivityType(param){
        return axios.post(`${base.base}/Activity/DeleteActivityType`,param)
    },

    //获取活动类型-目录树
    SelectTreeActivityTypeList(){
        return axios.get(`${base.base}/Activity/SelectTreeActivityTypeList`)
    },

    //#endregion

    //#region 凭证类型

    //列表
    ListProofType(param){
        return axios.post(`${base.base}/proof/ListProofType`,param)
    },

    //单个信息
    SingleProofType(param){
        return axios.get(`${base.base}/proof/SingleProofType`,{
            params:param
        })
    },

    //保存
    SaveProofType(param){
        return axios.post(`${base.base}/proof/SaveProofType`,param)
    },

    //删除
    DeleteProofType(param){
        return axios.post(`${base.base}/proof/DeleteProofType`,param)
    },

    //目录树
    SelectTreeProofTypeList(){
        return axios.get(`${base.base}/proof/SelectTreeProofTypeList`)
    },

    //#endregion

    //#region 凭证管理

    //列表
    ListProof(param){
        return axios.post(`${base.base}/proof/ListProof`,param)
    },

    //单个
    SingleAllProof(param){
        return axios.get(`${base.base}/proof/SingleAllProof`,{
            params:param
        })
    },

    //保存
    SaveAllProof(param){
        return axios.post(`${base.base}/proof/SaveAllProof`,param)
    },

    //批量删除
    DeleteAllProof(param){
        return axios.post(`${base.base}/proof/DeleteAllProof`,param)
    },

    //删除单个明细
    DeleteProofDetail(param){
        return axios({
            url:`${base.base}/proof/DeleteProofDetail`,
            method:'post',
            params:param
        })
    },

    //#endregion
 
    //#region 普通合同
    
    //合同列表
    PageContractList(param){
        return axios.post(`${base.base}/Contract/PageContractList`,param)
    },

    //获取单个合同
    SingleContractAllInfo(param){
        return axios.get(`${base.base}/Contract/SingleContractAllInfo`,{
            params:param
        })
    },

    //保存合同
    SaveContractAllInfo(param){
        return axios.post(`${base.base}/Contract/SaveContractAllInfo`,param)
    },

    //删除合同列表
    DeleteContract(param){
        return axios.post(`${base.base}/Contract/DeleteContract`,param)
    },

    //#endregion

    //#region 导出列表

    //列表
    PageExportList(param){
        return axios.post(`${base.base}/Export/PageExportList`,param)
    },

    //单个
    GetExportInfo(param){
        return axios.get(`${base.base}/Export/GetExportInfo`,{
            params:param
        })
    },

    //保存
    SaveExportInfo(param){
        return axios.post(`${base.base}/Export/SaveExportInfo`,param)
    },

    //批量删除
    DeleteExport(param){
        return axios.post(`${base.base}/Export/DeleteExport`,param)
    },

    //#endregion

 }

 export default oa