<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="退零单号">
                <el-input v-model="info.search.retailNumber" class="search-150" placeholder="零售单号"></el-input>
            </el-form-item>

            <el-form-item label="原始单号">
                <el-input v-model="info.search.originNumber" class="search-150" placeholder="原始单号"></el-input>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="大区">
                <el-select v-model="info.search.areaNumberList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                    <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-200">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="状态">
                <el-select v-model="info.search.state" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="未支付"></el-option>
                    <el-option :value="5" label="已支付"></el-option>
                    <el-option :value="9" label="已取消"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="业务标识">
                <el-select v-model="info.search.billFlag" class="search-200">
                    <el-option :value="-1" label="全部"></el-option>
                    <el-option :value="0" label="未创建"></el-option>
                    <el-option :value="1" label="已创建"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="创建日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>       
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" show-summary border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="billNumber" type="selection" width="55" />  
        <el-table-column label="编号" width="150">
            <template #default="scope">
                <span>{{scope.row.billNumber}}</span>
            </template>
        </el-table-column>
        <el-table-column label="部门" min-width="110">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="班次" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.shiftName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.shiftName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
        </el-table-column>
        
        <el-table-column label="交易者类型" min-width="96">
            <template v-slot="scope">
                <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="交易者" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.tradeName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="accountName" label="交易账户" min-width="100"/>
        <el-table-column prop="totalPrice" label="应付金额(元)" min-width="100"/>
        <el-table-column prop="realPrice" label="实付金额(元)" min-width="100"/>
        <el-table-column label="支付状态" min-width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.state === 1" type="danger">未支付</el-tag>
                <el-tag v-if="scope.row.state === 5" type="info">已支付</el-tag>
                <el-tag v-if="scope.row.state === 9" type="info">已取消</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="业务单标识" min-width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.billFlag == 0" type="danger">未生成</el-tag>
                <el-tag v-if="scope.row.billFlag == 1" type="info">已生成</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="原始单号" min-width="160">
            <template #default="scope">
                <span>{{scope.row.originNumber}}</span>
            </template>
        </el-table-column>

        <el-table-column label="创建时间" min-width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="付款时间" min-width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.payTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.payTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column label="制单人" property="createName" show-overflow-tooltip min-width="90"></el-table-column>
        
        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.billNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
            </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>
    </div>

    <!-- 分页 -->
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>


    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditReturnRetail :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditReturnRetail>
    </el-drawer>

  </div>
</template>

<script>
import EditReturnRetail from '@/views/Rms/ReturnRetail/EditReturnRetail.vue'  
import UserDptList from '@/utils/mixins/UserDptList.js'   //用户关联部门列表
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,AllTradeList,UserAreaList],
    components:{
        EditReturnRetail
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{                
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    billNumber:'',   //退零单号
                    originNumber:'',  //原始单号
                    selectDepartmentList:[],
                    areaNumberList:[],    //大区列表
                    tradeNumber:'',  //交易者编号
                    state:0,   //状态
                    billFlag:-1,  //业务单标识
                    datePoint:'',
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'零售单'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-180
        }
    },
    methods:{
       
        //加载数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                billNumber:this.info.search.billNumber,
                originNumber:this.info.search.originNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,   //大区列表
                tradeNumber:this.info.search.tradeNumber,
                billFlag:this.info.search.billFlag,
                state:this.info.search.state,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.erp.PageReturnRetailList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e);
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.billNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增退零单"
            }else{
                this.BoxEditInfo.title = "编辑退零单"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(billNumber){
            let param = [billNumber]
            this.$api.erp.DeleteReturnRetailAllInfo(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.erp.DeleteReturnRetailAllInfo(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.billNumber = '';
            this.info.search.originNumber='';
            this.info.search.selectDepartmentList=[];  //所属部门
            this.info.search.areaNumberList=[];      //大区列表
            this.info.search.tradeNumber='';
            this.info.search.billFlag=-1,
            this.info.search.state=0;
            this.info.search.datePoint = '';
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList();   
    }

}
</script>

<style>

</style>