<template>
  <div>
    <!-- 库存明细账 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="出入库类型">
                <el-select v-model="info.search.outInType" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="出库"></el-option>
                    <el-option :value="2" label="入库"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="原始单据类型">
                <el-select v-model="info.search.originType" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="采购单"></el-option>
                    <el-option :value="2" label="销售单"></el-option>
                    <el-option :value="3" label="零售单"></el-option>
                    <el-option :value="6" label="加工单"></el-option>
                    <el-option :value="7" label="盘点单"></el-option>
                    <el-option :value="8" label="报损单"></el-option>
                    <el-option :value="13" label="移仓单"></el-option>
                    <el-option :value="14" label="退供单"></el-option>
                    <el-option :value="15" label="退货单"></el-option>
                    <el-option :value="18" label="退零单"></el-option>
                    <el-option :value="20" label="入库单"></el-option>
                    <el-option :value="21" label="出库单"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="原始单据编号">
                <el-input v-model="info.search.originNumber" :maxlength="25" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="allDptList" multiple :default-expand-all="true"  class="search-200" collapse-tags collapse-tags-tooltip>
                </el-tree-select>
            </el-form-item>

            <el-form-item label="大区">
                <el-select v-model="info.search.areaNumberList" multiple collapse-tags collapse-tags-tooltip class="search-150">
                    <el-option v-for="areaInfo in allAreaList" :key="areaInfo.areaNumber" :label="areaInfo.areaName" :value="areaInfo.areaNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable  class="search-150">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="仓库">
                <el-select v-model="info.search.selectWarehouseList" multiple collapse-tags collapse-tags-tooltip class="search-150">
                    <el-option v-for="item in allWhList" :key="item.id" :label="item.warehouseName" :value="item.warehouseNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="产品类目">
                <el-tree-select v-model="info.search.cid" :data="selectCategoryList" :default-expand-all="true" filterable class="search-150"/>
            </el-form-item>

            <el-form-item label="名称">
                <el-input v-model="info.search.searchName" placeholder="品牌、分类、产品名称" class="search-200"></el-input>
            </el-form-item>

            <el-form-item label="日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                        
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group>
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe show-summary :height="GetDataHeight" style="width: 100%" @row-dblclick="GetOutInStockBill">
        <el-table-column label="日期" width="110" fixed="left">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.inOutTime"
                    placement="top"
                  >
                  <span>{{DateFormat(scope.row.inOutTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="凭证" align="center"> 
            <el-table-column label="字" align="center">
                <template v-slot="scope">
                    <span v-if="scope.row.originType == 1">采购</span>
                    <span v-if="scope.row.originType == 2">销售</span>
                    <span v-if="scope.row.originType == 3">零售</span>
                    <span v-if="scope.row.originType == 6">加工</span>
                    <span v-if="scope.row.originType == 7">盘点</span>
                    <span v-if="scope.row.originType == 8">报损</span>
                    <span v-if="scope.row.originType == 13">移仓</span>
                    <span v-if="scope.row.originType == 14">退供</span>
                    <span v-if="scope.row.originType == 15">退货</span>
                    <span v-if="scope.row.originType == 18">退零</span>
                    <span v-if="scope.row.originType == 20">入库</span>
                    <span v-if="scope.row.originType == 21">出库</span>
                </template>
            </el-table-column>
            <el-table-column label="号" align="center" width="80">
                <template v-slot="scope">
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.originNumber"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.originNumber}}</div>
                </el-tooltip>
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column label="出入库" width="90">
            <template v-slot="scope">
                <el-tag v-if="scope.row.outInType == 1" type="danger">出库</el-tag>
                <el-tag v-if="scope.row.outInType == 2">入库</el-tag>
            </template>
        </el-table-column>

        <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
        </el-table-column>

        <el-table-column label="仓库" width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.warehouseName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.warehouseName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="cidName" label="产品类目" width="110" />
        <el-table-column prop="brandName" label="品牌" />
        <el-table-column label="产品" width="150">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="规格" width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.skuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.skuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="unitName" label="默认单位" min-width="90">
            <template v-slot="scope">
                <span class="defUnitItem">{{scope.row.unitName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="minUnitName" label="最小单位" min-width="90"/>
        <el-table-column label="交易者类型" width="110">
            <template v-slot="scope">
                <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="交易者" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.tradeName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column label="摘要">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.summary"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.summary}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        
        <el-table-column label="借方(入库)" align="center">
            <el-table-column label="数量(默)" align="center">
                <template v-slot="scope">
                    <span class="defUnitItem">{{scope.row.inNum}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单价(默)" align="center">
                <template v-slot="scope">
                    <span class="defUnitItem">{{this.myRound(scope.row.inUnitPrice,2)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="数量(小)" align="center">
                <template v-slot="scope">
                    <span>{{scope.row.minInNum}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单价(小)" align="center">
                <template v-slot="scope">
                    <span>{{this.myRound(scope.row.minInUnitPrice,2)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="inTotalPrice" label="合价" align="center" min-width="120">
                <template v-slot="scope">
                    <span class="totalPrice">{{this.myRound(scope.row.inTotalPrice,2)}}</span>
                </template>
            </el-table-column>
        </el-table-column>

        <el-table-column label="贷方(出库)" align="center">
            <el-table-column label="数量(默)" align="center">
                <template v-slot="scope">
                    <span class="defUnitItem">{{scope.row.outNum}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单价(默)" align="center">
                <template v-slot="scope">
                    <span class="defUnitItem">{{this.myRound(scope.row.outUnitPrice,2)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="数量(小)" align="center">
                <template v-slot="scope">
                    <span>{{scope.row.minOutNum}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单价(小)" align="center">
                <template v-slot="scope">
                    <span>{{this.myRound(scope.row.minOutUnitPrice,2)}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="outTotalPrice" label="合价" align="center" min-width="120">
                <template v-slot="scope">
                    <span class="totalPrice">{{this.myRound(scope.row.outTotalPrice,2)}}</span>
                </template>
            </el-table-column>
        </el-table-column>

        <el-table-column label="产品余额(库存)" align="center">
            <el-table-column label="数量(默)" align="center">
                <template v-slot="scope">
                    <span class="defUnitItem">{{scope.row.surplusNum}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单价(默)" align="center">
                <template v-slot="scope">
                    <span class="defUnitItem">{{this.myRound(scope.row.surplusUnitPrice,2)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="数量(小)" align="center">
                <template v-slot="scope">
                    <span>{{scope.row.minSurplusNum}}</span>
                </template>
            </el-table-column>
            <el-table-column label="单价(小)" align="center">
                <template v-slot="scope">
                    <span>{{this.myRound(scope.row.minSurplusUnitPrice,2)}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="surplusTotalPrice" label="合价" align="center" min-width="120">
                <template v-slot="scope">
                    <span class="totalPrice">{{this.myRound(scope.row.surplusTotalPrice,2)}}</span>
                </template>
            </el-table-column>
        </el-table-column>

        <el-table-column label="库房余额" width="120">
            <template v-slot="scope">
                <span class="totalPrice">{{this.myRound(scope.row.warehouseTotalPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="系统余额" width="150">
            <template v-slot="scope">
                <span class="totalPrice">{{this.myRound(scope.row.systemTotalPrice,2)}}</span>
            </template>
        </el-table-column>

        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 明细-弹出框 -->
    <el-dialog :title="BoxDetail.title"
    v-model="BoxDetail.isVisible"
    width="70%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <LookOriginBill :selectRow="info.selectRow"></LookOriginBill>
        <template #footer>
            <el-button @click="this.BoxDetail.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import LookOriginBill from '@/views/Ma/StockDetail/LookOriginBill.vue' //明细页

import CategoryList from '@/utils/mixins/CategoryList.js'  //产品类目
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
import AllAreaList from '@/utils/mixins/AllAreaList.js'  //全部大区列表
import AllWhList from '@/utils/mixins/AllWhList.js'   //全部仓库列表

import { ElLoading, ElMessage } from 'element-plus'
export default {
    mixins:[CategoryList,AllTradeList,AllDptList,AllAreaList,AllWhList],
    components:{
        LookOriginBill
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    outInType:0,  //出入库类型
                    originType:0,   //原始单据类型
                    originNumber:'',  //原始单据编号
                    selectDepartmentList:[],  //选中的部门列表
                    areaNumberList:[],   //大区列表
                    selectWarehouseList:[],  //选中的仓库列表     
                    tradeNumber:'', //交易者       
                    cid:0,      //产品类目
                    searchName:'',  //名称
                    datePoint:'',   //时间范围
                },
                selectRow:{},   //选中行
            },
            //明细-弹出框
            BoxDetail:{
                title:'出入库凭证',
                isVisible:false
            },
            //统计-弹出框
            BoxStat:{
                isVisible:false,
            },
        }
    },
    computed:{
        //Table自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-180
        }
    },
    methods:{
        // ...mapActions('Master',['work_AddTag']),

        //获取数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }
            let param = {
                outInType:this.info.search.outInType,
                originType:this.info.search.originType,
                originNumber:this.info.search.originNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                warehouseList:this.info.search.selectWarehouseList, 
                tradeNumber:this.info.search.tradeNumber,
                cid:this.info.search.cid,
                searchName:this.info.search.searchName,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PageStockDetailList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //导出库存账到Excel
        btnExportInfo(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }
            let param = {
                outInType:this.info.search.outInType,
                originType:this.info.search.originType,
                originNumber:this.info.search.originNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                warehouseList:this.info.search.selectWarehouseList, 
                tradeNumber:this.info.search.tradeNumber,
                cid:this.info.search.cid,
                searchName:this.info.search.searchName,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在导出数据,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })
            this.$api.finance.ExportStockDetailList(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    const alink = document.createElement('a');
                    alink.href = res.data.data;
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink)
                }else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.outInType=0;
            this.info.search.originType = 0;
            this.info.search.originNumber ='';
            this.info.search.selectDepartmentList=[];
            this.info.search.areaNumberList=[];    //大区列表
            this.info.search.selectWarehouseList=[];
            this.info.search.warehouseNumber='';
            this.info.search.tradeType = 0;
            this.info.search.tradeNumber='';
            this.info.search.cid = 0;
            this.info.search.searchName='';
            this.info.search.datePoint='';
            this.info.page = 1;
            this.GetDataList();
        },

        //查看原始单据
        GetOutInStockBill(row){
            this.info.selectRow = row;
            this.BoxDetail.isVisible = true;
        },

    },
    mounted(){
        this.GetDataList();  //加载数据
    },
}
</script>

<style scoped>
.totalPrice{color: red;}
.defUnitItem{color: rgb(57, 116, 245);}
</style>