<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="编号">
                <el-input v-model="info.search.wageNumber" :maxlength="25" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="月份">
                <el-date-picker v-model="info.search.month" type="month" :editable="false" placeholder="选择月份" />
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="allDptList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>
            
            <el-form-item label="员工姓名">
                <el-select v-model="info.search.userNumber" filterable clearable class="search-200">
                    <el-option v-for="info in selectUserList" :key="info.userNumber" :label="info.userName" :value="info.userNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="状态">
                <el-select v-model="info.search.state" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="新建单"></el-option>
                    <el-option :value="2" label="待审核"></el-option>
                    <el-option :value="3" label="已驳回"></el-option>
                    <el-option :value="4" label="审核并上报"></el-option>
                    <el-option :value="5" label="审核已完成"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="单据类型">
                <el-select v-model="info.search.billType" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="工资"></el-option>
                    <el-option :value="2" label="奖金"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="财务标识">
                <el-select v-model="info.search.financeFlag" class="search-150">
                    <el-option :value="-1" label="全部"></el-option>
                    <el-option :value="0" label="未生成"></el-option>
                    <el-option :value="1" label="已生成"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnWageAdd')" class="iconfont icon-add" type="primary" plain @click="BoxBatchInfo.isVisible=true">批量新增</el-button>
                <!-- <el-button class="iconfont icon-add" type="primary" @click="btnEditInfo(0)">新增</el-button> -->
                <el-button v-if="CheckUserButtonAuth('BtnWageAddFinance')" class="iconfont icon-dingdan" type="warning" plain @click="showCreateFinance()">生成财务单</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnWageDelete')" 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportList"></el-button>
                    </el-tooltip>
                </el-button-group>         
                
            </el-col>
        </el-row>

        <!-- 工资单 -->
        <el-table :data="dataList" show-summary border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="wageNumber" type="selection" width="55"></el-table-column>
            <el-table-column prop="wageNumber" label="编号" width="170" />
            <el-table-column prop="month" label="月份"></el-table-column>
            <el-table-column prop="departmentName" label="部门"></el-table-column>
            <el-table-column prop="userName" label="员工姓名">
                <template v-slot="scope">
                    <el-link type="danger" @click="btnSingleWageAllInfo(scope.row.wageNumber)">{{scope.row.userName}}</el-link>
                </template>
            </el-table-column>
            <el-table-column label="单据类型">
                <template #default="scope">
                    <el-tag v-if="scope.row.billType ==1">工资</el-tag>
                    <el-tag v-if="scope.row.billType ==2" type="danger">奖金</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="realWage" label="实发金额(元)"></el-table-column>
            <el-table-column label="状态">
                <template #default="scope">
                    <el-tag v-if="scope.row.state ==1" type="info">新建单</el-tag>
                    <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==4" type="warning">审核通过并上报</el-tag>
                    <el-tag v-if="scope.row.state ==5" type="success">审核已完成</el-tag>
                    <el-tag v-if="scope.row.state ==10">已关单</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="receiverName" label="审核人"></el-table-column>
            <el-table-column label="财务标识">
                <template #default="scope">
                    <el-tag v-if="scope.row.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="scope.row.financeFlag == 1" type="info">已生成</el-tag>
                </template>
            </el-table-column>  
            <el-table-column label="操作" width="130" fixed="right">
                <template v-slot="scope">
                    <el-button size="small" type="primary" @click="btnEditInfo(scope.row.wageNumber)">详情</el-button>
                    <el-popconfirm v-if="CheckUserButtonAuth('BtnWageDelete')" 
                        title="确认要删除吗?"
                        @confirm="btnDeleteInfo(scope.row.wageNumber)">
                        <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditWage :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditWage>
    </el-drawer>

    <!-- 批量生成工资单 -->
    <el-dialog :title="BoxBatchInfo.title"
    v-model="BoxBatchInfo.isVisible"
    width="45%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <BatchSaveWage @closeBatchBox="btnBatchCloseBox"></BatchSaveWage>
    </el-dialog>

    <!-- 审核-弹框 -->
    <el-dialog title="工资单审核"
    v-model="BoxCheckState.isVisible"
    width="20%">
        <el-divider></el-divider>
        <div>工资单是否审核通过?</div>
        <template #footer>            
            <el-button type="primary" @click="btnUpdateWateState(3)">通过</el-button>
            <el-button type="danger" @click="btnUpdateWateState(2)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

    <!-- 生成财务单-弹框 -->
    <el-dialog title="生成财务单"
    v-model="BoxCreateFinance.isVisible"
    width="45%"
    :destroy-on-close="true"
    @closed="GetDataList">
        <BoxCreateFinance :financeInfo="financeInfo" ref="newFinanceInfo"></BoxCreateFinance>
        <template #footer>            
            <el-button type="primary" @click="btnCreateFinance()">生成财务单</el-button>
            <el-button @click="BoxCreateFinance.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import EditWage from '@/views/Oa/Wage/EditWage.vue'
import BatchSaveWage from '@/views/Oa/Wage/BatchSaveWage.vue'
import BoxCreateFinance from '@/components/common/BoxCreateFinance.vue'  //生成财务单

import AllUserList from '@/utils/mixins/AllUserList.js'  //全部用户列表
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表

import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
    components:{
        EditWage,
        BatchSaveWage,
        BoxCreateFinance
    },
    mixins:[AllUserList,AllDptList],
    data(){
        return{
            dataList:[],  //Table数据
            info:{                
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数                
                search:{
                    wageNumber:'',   //编号
                    month:'',
                    userNumber:'',
                    selectDepartmentList:[],
                    state:0,
                    billType:0,
                    financeFlag:-1,
                }
            },
            financeInfo:{
                numberList:[],  //编号数组
                priceList:[], 
                departmentId:0,
                billType:2, //财务类型： 工资单默认时支出
                subId:0,
                totalPrice:0,
                tradeType:3,
                billTime:moment(new Date()).format("YYYY-MM-DD")
            },  //生成财务单信息
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'工资单详情'
            },
            //批量生成工资单-弹出框
            BoxBatchInfo:{
                isVisible:false,
                title:'新增工资单'
            },
            //审核-弹出框
            BoxCheckState:{
                isVisible:false,
            },
            //生成财务单-弹出框
            BoxCreateFinance:{
                isVisible:false,
            },

        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-180
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            //月份
            let strMonth = "";
            if(this.info.search.month!=null && this.info.search.month!=''){
                strMonth = moment(this.info.search.month).format("YYYY-MM");
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                wageNumber:this.info.search.wageNumber,
                month:strMonth,
                departmentList:newDepartmentList,
                userNumber:this.info.search.userNumber,
                state:this.info.search.state,
                billType:this.info.search.billType,
                financeFlag:this.info.search.financeFlag,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.oa.PageWageList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.wageNumber)
            this.financeInfo.numberList = selection.map(item=>item.wageNumber)  //编号数组
            this.financeInfo.priceList = selection.map(item=> parseFloat(item.realWage))  //计算总金额
            this.financeInfo.tradeNumber = selection.find(item=>item).userNumber;  //交易者编号=员工编号
            this.financeInfo.departmentId = selection.find(item=>item).departmentId;  //部门
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //新增、编辑信息
        btnEditInfo(wageNumber){
            this.info.id = wageNumber
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },
        //关闭批量保存弹出框
        btnBatchCloseBox(){
            this.BoxBatchInfo.isVisible = false;
        },
        //删除按钮
        btnDeleteInfo(wageNumber){
            let param = [wageNumber]
            this.$api.oa.DeleteWageAllInfo(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.oa.DeleteWageAllInfo(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.wageNumber='';
            this.info.search.month = '';
            this.info.search.selectDepartmentList = [];
            this.info.search.userNumber = '';
            this.info.search.state=0;
            this.info.search.billType=0;
            this.info.search.financeFlag=-1;
            this.info.page = 1;
            this.GetDataList()
        },  

        //弹出框-单个审核
        btnShowCheckWageBox(wageNumber){
            this.info.id = wageNumber
            this.BoxCheckState.isVisible =true;
        },
        //提交审核结果
        btnUpdateWateState(state){
          let param = {
            wageNumber:this.info.id,
            state:state
          }
          this.$api.oa.UpdateWageState(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                //
                this.BoxCheckState.isVisible = false;
                this.GetDataList()
            }else{
                ElMessage.error(res.data.msg);
            }
          })
        },

        //批量审核
        btnBatchCheck(){
            ElMessage.warning('功能开发中');
        },

        //弹出-生成财务单
        showCreateFinance(){
            if(this.info.ids.length<1){
                ElMessage({type:'error',message:'请选择要生成财务单的单据'})
                return false
            }
            //计算总金额
            this.financeInfo.totalPrice = 0;
            this.financeInfo.priceList.forEach(element => {
                this.financeInfo.totalPrice =  this.financeInfo.totalPrice + element
            });  

            this.BoxCreateFinance.isVisible =true
        },
        //保存-工资单生成财务单
        btnCreateFinance(){
            //验证-部门
            if(this.$refs.newFinanceInfo.info.departmentId ===0){
                ElMessage.error("请选择部门");
                return false;
            }

            //验证-会计科目
            if(this.$refs.newFinanceInfo.info.subId ===0){
                ElMessage.error("请选择会计科目");
                return false;
            }
            
            this.$api.oa.CreateFinance(this.$refs.newFinanceInfo.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //关闭
                    this.BoxCreateFinance.isVisible = false;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //导出工资单
        btnExportList(){
            //月份
            let strMonth = "";
            if(this.info.search.month!=null && this.info.search.month!=''){
                strMonth = moment(this.info.search.month).format("YYYY-MM");
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                wageNumber:this.info.search.wageNumber,
                month:strMonth,
                departmentList:newDepartmentList,
                userNumber:this.info.search.userNumber,
                state:this.info.search.state,
                billType:this.info.search.billType,
                financeFlag:this.info.search.financeFlag,
                page:this.info.page,
                size:this.info.size
            }

            this.$api.oa.ExportWage(param).then(res=>{
                if(res.data.status === 200){
                    const alink = document.createElement('a');
                    alink.href = res.data.data;
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink)
                }
                else
                {
                    ElMessage.error(res.data.msg)
                }
            })
        },

    },
    mounted(){
        this.GetDataList();
    }
}
</script>

<style>

</style>