<template>
  <div>
    <!-- 导出列表 -->
    
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="导出单号">
                    <el-input v-model="info.search.exportNumber" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="标题">
                    <el-input v-model="info.search.title" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="单据类型">
                    <el-select v-model="info.search.originType" class="search-150">
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="采购单"></el-option>
                        <el-option :value="2" label="销售单"></el-option>
                        <el-option :value="7" label="盘点单"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
         <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
                
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                                
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" border stripe :height="GetDataHeight" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="exportNumber" type="selection" width="55" />  
            <el-table-column prop="exportNumber" label="导出单号"  width="170"></el-table-column>
            
            <el-table-column label="单据类型" width="85">
                <template #default="scope">
                    <el-tag v-if="scope.row.originType == 1" type="danger">采购单</el-tag>
                    <el-tag v-if="scope.row.originType == 2" type="success">销售单</el-tag>
                    <el-tag v-if="scope.row.originType == 3" type="info">零售单</el-tag>
                    <el-tag v-if="scope.row.originType == 4" type="warning">差额单</el-tag>                    
                    <el-tag v-if="scope.row.originType == 7" type="warning">盘点单</el-tag>
                    <el-tag v-if="scope.row.originType == 14" type="warning">退供单</el-tag>
                    <el-tag v-if="scope.row.originType == 15" type="warning">退货单</el-tag>
                </template>
            </el-table-column> 
            
            <el-table-column prop="title" label="标题" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column label="文件">
                <template #default="scope">
                    <el-link :href="scope.row.fileUrl" target="_blank">文件下载</el-link>
                </template>
            </el-table-column>

            <el-table-column label="状态" width="100">
                <template #default="scope">
                    <el-tag v-if="scope.row.state == 1" type="danger">待查询</el-tag>
                    <el-tag v-if="scope.row.state == 2" type="info">已完成</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="备注" min-width="100">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.remark"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.remark}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="创建日期" width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.createTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="完成日期" width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.completeTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.completeTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="创建人" width="100" prop="createName" show-overflow-tooltip></el-table-column>

            <el-table-column label="操作" fixed="right" width="130">
                <template v-slot="scope">
                    <el-button size="small" type="primary" @click="btnEditInfo(scope.row.exportNumber)">详情</el-button>
                    <el-button size="small" type="danger" @click="btnDeleteInfo(scope.row.exportNumber)">删除</el-button>
                </template>
            </el-table-column>

        </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 单个信息-弹出框 -->
    <el-dialog title="导出记录"
    v-model="BoxEditInfo.isVisible"
    width="650"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditExport :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditExport>
    </el-dialog>

  </div>
</template>

<script>
import EditExport from '@/views/Oa/Export/EditExport.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    components:{
        EditExport,
    },
    data(){
        return{
            dataList:[],  //数据列表
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    exportNumber:'',
                    originType:0,
                    title:'',
                    datePoint:'',
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'导出记录'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-180
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }
            let param = {
                exportNumber:this.info.search.exportNumber,
                originType:this.info.search.originType,
                title:this.info.search.title,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.oa.PageExportList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.exportNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //删除按钮
        btnDeleteInfo(exportNumber){
            ElMessageBox.confirm('确定要删除该选项吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                //确定
                let param = [exportNumber]
                this.$api.oa.DeleteExport(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList()
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })

            
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }
            
            ElMessageBox.confirm('确定要删除选中项吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                //确定
                this.$api.oa.DeleteExport(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                        this.GetDataList();
                        this.info.ids = [];
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })            
        },

        //新增、编辑
        btnEditInfo(exportNumber){
            this.info.id = exportNumber;
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },


        //重置
        btnResetSearch(){
            this.info.search.exportNumber='';
            this.info.search.originType=0;
            this.info.search.title='';
            this.info.search.datePoint = '';
            this.GetDataList();
        },
    },
    mounted(){
        this.GetDataList();  //获取列表
    },
}
</script>

<style>

</style>