/**
 * OA办公相关数据
 * 
 */

 var actions = {

}

var mutations = {

    //设置合同数据
    SetContractInfo(state,value){
        state.contractInfo = value
    },

    //设置工资单数据
    SetWageInfo(state,value){
        state.wageInfo = value
    },

    //设置人事档案
    SetStaffInfo(state,value){
        state.staffInfo = value
    },

    //设置组信息
    SetGroupInfo(state,value){
        state.groupInfo = value
    },

    //设置审批单
    SetProcessInfo(state,value){
        state.processInfo = value
    },

    //设置凭证信息
    SetProofInfo(state,value){
        state.proofInfo = value
    },

    //设置知识信息
    SetKnowledgeInfo(state,value){
        state.knowledgeInfo = value
    },
    
}

var state = {
    contractInfo:{},     //合同数据
    wageInfo:{},         //工资单
    staffInfo:{},        //人事档案
    groupInfo:{},       //组信息
    processInfo:{},     //审批单
    proofInfo:{},      //凭证信息
    knowledgeInfo:{},   //知识信息
}

export default{
    namespaced:true,
    actions,
    mutations,
    state
}