<template>
  <div>

    <el-table :data="knowledgeInfo.fileList" border stripe style="width: 100%" max-height="300px">

        <el-table-column label="文件">
            <template v-slot="scope">
                <!-- 图片 -->
                <el-image v-if="scope.row.fileType==1"
                    style="width: 70px; height: 70px;border:1px solid #ccc;"
                    :src="scope.row.fileUrl"
                    :preview-src-list="knowledgeInfo.imgList"
                    :initial-index="scope.$index"
                    :hide-on-click-modal="true"
                    :infinite="false"
                    fit="cover"
                    />
                <!-- 文件 -->
                <el-link v-if="scope.row.fileType==2" :href="scope.row.fileUrl" target="_blank">{{scope.row.title}}</el-link>
            </template>
        </el-table-column>
        <el-table-column label="类型">
            <template v-slot="scope">
                <el-tag v-if="scope.row.fileType == 1">图片</el-tag>
                <el-tag v-if="scope.row.fileType == 2">文件</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="remark" label="操作" >
            <template v-slot="scope">
                <el-button v-if="CheckUserButtonAuth('BtnKnowledgeFileDel')" size="small" type="danger" @click="btnDeleteKnowledgeDetail(scope.row.knowledgeNumber,scope.row.id,scope.$index)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Oa',['knowledgeInfo']),
    },
    methods:{
        //删除单个文件
        btnDeleteKnowledgeDetail(knowledgeNumber,id,index){
            this.knowledgeInfo.fileList.splice(index,1);
            if(id!=0){
                let param = {
                    knowledgeNumber:knowledgeNumber,
                    id:id,
                }
                this.$api.oa.DeleteKnowledgeFile(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg);
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
        },
    }
}
</script>

<style>

</style>