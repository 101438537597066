<template>
  <div>
    <!-- 按钮 -->
    <div class="skuBtnList" v-if="checkInfo.info && checkInfo.info.state==1">
      <el-button class="iconfont icon-add" type="primary" plain @click="btnSelectSku">选择产品</el-button>
    </div>

    <!-- 盘点列表 -->
    <el-table :data="checkInfo.detailList" border stripe show-summary style="width: 100%">
      <el-table-column prop="warehouseName" label="仓库" min-width="100"/>
      <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
      <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
      <el-table-column prop="skuName" label="产品名称" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
      <el-table-column prop="skuName" label="规格" min-width="100"/>
      <el-table-column label="单位" width="110">
        <template v-slot="scope">
            <span>{{scope.row.unitName}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column v-if="setUserInfo && setUserInfo.stockPrice==1" prop="costUnitPrice" label="成本单价(元)" min-width="110">
        <template v-slot="scope">
            <span>{{this.myRound(scope.row.costUnitPrice,2)}}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="stockNum" label="库存数量" min-width="90" />
      <el-table-column label="盘点数量" min-width="100">
        <template v-slot="scope">
            <span>{{scope.row.checkNum}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="diffNum" label="差额数量" min-width="90">
        <template v-slot="scope">
            <span>{{this.myRound(scope.row.diffNum,2)}}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column v-if="setUserInfo && setUserInfo.stockPrice==1" prop="costTotalPrice" label="成本总价(元)" min-width="110">
        <template v-slot="scope">
            <span>{{this.myRound(scope.row.costTotalPrice,2)}}</span>
        </template>
    </el-table-column> -->

      <el-table-column label="状态" min-width="100">
        <template v-slot="scope">
            <el-tag v-if="scope.row.checkState == 0" type="warning">未盘点</el-tag>
            <el-tag v-if="scope.row.checkState == 1" type="danger">待复盘</el-tag>
            <el-tag v-if="scope.row.checkState == 2" type="info">已盘点</el-tag>
        </template>
      </el-table-column>
    
      <el-table-column label="盘点时间" min-width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.checkTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.checkTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column label="提示" width="60">
            <template #default="scope">
                <el-tooltip v-if="scope.row.lockStockFlag == 1"
                    class="box-item"
                    effect="dark"
                    content="该产品有锁定库存,点击查看"
                    placement="top">
                    <el-button type="danger" circle size="small" @click="btnOpenBoxLockStock(scope.row.lockStockAllInfo)">锁</el-button>
                </el-tooltip>
            </template>
        </el-table-column>

      <el-table-column label="操作" width="130" fixed="right">
        <template v-slot="scope">
            <!-- 编辑、删除 -->
            <el-button size="small" type="primary" @click="btnOpenBoxDetail(scope.row.id)">详情</el-button>
            <el-button v-if="checkInfo.info && checkInfo.info.state ==1 && CheckUserButtonAuth('BtnCheckGoodsDel')" size="small" type="danger" @click="DeleteRow(scope.row.checkNumber,scope.row.id,scope.$index)">删除</el-button>
            <!-- 复盘 -->
            <el-button v-if="checkInfo.info && checkInfo.info.state ==3" size="small" type="danger" @click="btnUpdateCheckDetail(scope.row.checkNumber, scope.row.id, 1)">复盘</el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 选择产品 -->
    <el-dialog title="选择产品" 
        v-model="BoxSkuInfo.isVisible"
        width="65%"
        :destroy-on-close="true">
          <SelectStockSkuListTwo :areaNum="checkInfo.info.areaNumber" ref="SelectSkuInfo"></SelectStockSkuListTwo>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="btnSelectData">选择</el-button>
                <el-button @click="BoxSkuInfo.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

    <!-- 单个盘点-弹出框 -->
    <el-dialog title="盘点信息"
        v-model="BoxCheckDetail.isVisible"
        width="400"
        :draggable="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @closed="btnRefresh">
        <BoxCheckInfo :editId="BoxCheckDetail.id" @closeBox="btnCloseBoxDetail"></BoxCheckInfo>
    </el-dialog>

    <!-- 加载锁库单据列表 -->
    <el-dialog title="锁库单据"
    v-model="BoxLockStock.isVisible"
    width="500">
        <el-table :data="BoxLockStock.dataList" border stripe>
            <el-table-column label="部门" prop="departmentName" min-width="100" show-overflow-tooltip></el-table-column>
            <el-table-column label="单据编号" prop="billNumber" width="160" show-overflow-tooltip></el-table-column>
            <el-table-column label="单据类型" width="90">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.billType==2">销售单</el-tag>
                    <el-tag v-if="scope.row.billType==14" type="danger">退供单</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="交易者" prop="tradeName" width="100" show-overflow-tooltip></el-table-column>
        </el-table>

        <template #footer>
            <el-button @click="BoxLockStock.isVisible=false">关闭</el-button>
        </template>
    </el-dialog>

    <div class="remark">
        盘点单流程：创建盘点单 - 开始盘点 - 完成盘点 - 更新库存
    </div>

  </div>
</template>

<script>
import SelectStockSkuListTwo from '@/components/common/SelectStockSkuListTwo.vue'
import BoxCheckInfo from '@/views/Wms/Check/BoxCheckInfo.vue'   //盘点页面
import { mapState } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    components:{
        SelectStockSkuListTwo,
        BoxCheckInfo,
    },
    data(){
        return{
            //选择产品-弹出框
            BoxSkuInfo:{
                isVisible:false,
            },
            //盘点弹出框
            BoxCheckDetail:{
                isVisible:false,
                id:0,
            },
            //锁库列表弹出框
            BoxLockStock:{
                isVisible:false,
                dataList:[],   //锁库单据列表
            },
        }
    },
    computed:{
        ...mapState('Erp',['checkInfo']),
        ...mapState('SetUp',['setUserInfo']),  //个人设置
    },
    methods:{
        //选择产品
        btnSelectSku(){
            this.BoxSkuInfo.isVisible = true
        },
        //保存选中产品
        btnSelectData(){
            let itemList = this.$refs.SelectSkuInfo.selectedItem
            if(itemList !=null && itemList.length>0){
                itemList.forEach(item => {
                    let lockStockFlag = item.lockStockNum>0 ? 1 : 0;
                    let newRow = {
                        id:0,
                        systemNumber:'',
                        checkNumber:'',
                        warehouseNumber:item.warehouseNumber,
                        warehouseName:item.warehouseName,
                        cid:item.cid,
                        cidName:item.cidName,
                        brandId:item.brandId,
                        brandName:item.brandName,
                        spuNumber:item.spuNumber,
                        spuName:item.spuName,
                        skuNumber:item.skuNumber,
                        skuName:item.skuName,
                        unitId:item.defUnitId,   //默认单位编号
                        unitName:item.defUnitName,  //默认单位名称
                        costUnitPrice:item.defUnitPrice,  //成本单价 = 默认单位成本价
                        stockNum:0,  //默认单位-实际库存数
                        checkNum:0,  //盘点数
                        diffNum:0,  //差额数量=盘点数-默认单位库存数
                        costTotalPrice:0,     //总成本价
                        checkState:0,  //盘点状态
                        isDel:0,
                        isEdit:1,  //编辑状态
                        lockStockFlag:lockStockFlag,   //锁库状态
                        skuUnitList:item.skuUnitList,   //单位列表
                    }

                    this.checkInfo.detailList.push(newRow);                   
                });
                this.BoxSkuInfo.isVisible = false; //关闭选择产品弹出框
            }
        },

        //删除行
        DeleteRow(checkNumber,id,index){
            ElMessageBox.confirm('确定要删除该产品吗?','删除提示',{
                confirmButtonText:'确认',
                cancelButtonText:'取消',
                type:'warning'
            }).then(()=>{
                if(id !=0){
                    let param = {
                        checkNumber:checkNumber,
                        id:id
                    }
                    this.$api.wms.DeleteCheckDetail(param).then(res=>{
                        if(res.data.status === 200){
                            this.checkInfo.detailList.splice(index,1);
                            ElMessage.success(res.data.msg);
                        }else{
                            ElMessage.error(res.data.msg);
                        }
                    })
                }else{
                    this.checkInfo.detailList.splice(index,1);
                }
            }).catch(()=>{

            })            
        },

        //更新单个明细状态：2:复盘
        btnUpdateCheckDetail(checkNumber,id,state){
            let param = {
                checkNumber:checkNumber,
                detailId:id,
                state:state
            }
            this.$api.wms.UpdateStateOfDetail(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success('已更新')
                    //更新页面
                    this.checkInfo.detailList = this.checkInfo.detailList.map(element=>{
                        if(element.id == id){
                            element.checkState = state
                        }
                        return element;
                    })
                }
            })
        },

        //开始盘点-默认从第一个开始
        btnStartCheck(){
            this.BoxCheckDetail.id = this.checkInfo.detailList[0].id;  //默认第一个
            this.BoxCheckDetail.isVisible=true;
        },
        //打开盘点框
        btnOpenBoxDetail(id){
            this.BoxCheckDetail.id = id;
            this.BoxCheckDetail.isVisible=true;
        },
        //关闭盘点框
        btnCloseBoxDetail(){
            this.BoxCheckDetail.isVisible=false;
        },
        //刷新(调父组件重新加载数据)
        btnRefresh(){
            this.$emit('btnRefresh')
        },

        //打开锁库单据列表-弹出框
        btnOpenBoxLockStock(allInfo){
            this.BoxLockStock.dataList = allInfo.infoList;  //加载锁库单据列表
            this.BoxLockStock.isVisible=true;
        },

    }
}
</script>

<style scoped>
.remark {font-size: 14px;color: #ccc;}
</style>