<template>
  <div>

    <el-form :inline="true"
    v-model="knowledgeInfo.info"
    label-width="80px"
    label-position="right">
        <el-row v-if="knowledgeInfo.info">

            <el-col :span="12">
                <el-form-item label="知识类型">
                    <template #label>
                    <span style="color:red;">*</span>
                    <span>知识类型</span>
                </template>
                <el-tree-select v-model="knowledgeInfo.info.typeId" :data="knowledgeTypeList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="知识标题">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>知识标题</span>
                    </template>
                    <el-input v-model="knowledgeInfo.info.title" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="说明">
                    <el-input v-model="knowledgeInfo.info.content" type="textarea" :rows="5" style="width:535px;" :maxlength="500" :show-word-limit="true"></el-input>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            knowledgeTypeList:[],   //知识类型列表
        }
    },
    computed:{
        ...mapState('Oa',['knowledgeInfo']),
    },
    methods:{
        //获取知识类型列表
        GetKnowledgeTypeList(){
            this.$api.oa.SelectTreeKnowledgeTypeList().then(res=>{
                if(res.data.status === 200){
                    this.knowledgeTypeList=res.data.data;
                }else{
                    this.knowledgeTypeList=[];
                }
            })
        },

    },
    mounted(){
        this.GetKnowledgeTypeList();
    }

}
</script>

<style>

</style>