<template>
  <div>
    <!-- 编辑薪酬初始项设置 -->
    <el-divider></el-divider>

    <!-- 薪酬初始项设置 -->
    <div>
      <el-form :inline="true"
        v-model="info"
        label-width="100px"
        label-position="right">
            <el-row>

                <el-col :span="24">
                    <el-form-item label="员工姓名">
                      <el-select v-model="info.userNumber" class="search-200" filterable clearable>
                        <el-option v-for="userInfo in selectUserList" :key="userInfo.userNumber" :label="userInfo.userName" :value="userInfo.userNumber"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="薪酬项目">
                      <el-select v-model="info.itemId" class="search-200" filterable clearable>
                        <el-option v-for="item in selectWageItemList" :key="item.itemId" :label="item.itemName" :value="item.itemId"></el-option>
                      </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="初始值">
                      <el-input-number  v-model="info.itemValue" :min="0" :precision="2" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

            </el-row>
      </el-form>      
    </div>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import AllUserList from '@/utils/mixins/AllUserList.js'
export default {
    name:'EditWageInitial',
    props:['editId','dataList'],
    mixins:[AllUserList],  //员工列表 - 下拉列表
    data(){
        return{
            info:{
                id:0,
                userNumber:'',
                itemId:0,
                itemValue:0
            },
            currentId:this.editId,  //当前编号
            selectWageItemList:[]  //薪酬项目-下拉列表
        }
    },
    methods:{
        //获取薪酬项目-下拉列表
        SelectWageItemList(){
            this.$api.oa.SelectWageItemList().then(res=>{
                if(res.data.status === 200){
                    this.selectWageItemList = res.data.data;
                    let newInfo = {itemId:0,itemName:'请选择'}
                    this.selectWageItemList.unshift(newInfo);
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },
        //获取数据
        GetDataInfo(){
            if(this.currentId!=0){
                let param = {
                    id:this.currentId
                }
                this.$api.oa.SingleWageInitial(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{
                        console.log(res.data.msg);
                    }
                })
            }else{
                this.info = {
                    id:0,
                    userNumber:'',
                    itemId:0,
                    itemValue:0
                }
            }      
        },

        //保存数据
        btnSaveData(){
            this.$api.oa.SaveWageInitial(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    //
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.id === this.currentId
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.currentId = prevInfo.id
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.id === this.currentId
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.currentId = prevInfo.id
                this.GetDataInfo()
            }
        },
        
    },
    mounted(){
        this.SelectWageItemList();  //薪酬项目-下拉列表
       this.GetDataInfo()
    }
}
</script>

<style>

</style>