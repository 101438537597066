<template>
  <div class="SingleInfo">
    <el-divider></el-divider>

    <!-- 知识主信息 -->
    <TabInfo></TabInfo>

    <TabInfoFile></TabInfoFile>

    <!-- 功能按钮 -->
    <div class="btnGroup newStyle">
        <el-upload v-if="CheckUserButtonAuth('BtnKnowledgeUpLoadFile')"
            :action="GetFileUrl('Knowledge')"
            style="margin-right:5px"
            :headers="GetHeader"
            :show-file-list="false"
            multiple
            :before-upload="BeforeUpload"
            :on-success="UploadSuccess"
            :on-error="UploadError">
                <el-button class="iconfont icon-add" type="primary" plain>上传文件</el-button>
        </el-upload>
      <el-button v-if="CheckUserButtonAuth('BtnKnowledgeSave')" type="primary" @click="btnSaveInfo()">保存</el-button>
      <el-button @click="btnClose">关闭</el-button>

      <el-button-group>
          <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
          <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
      </el-button-group>
    </div>

  </div>
</template>

<script>
import TabInfo from '@/views/Oa/Knowledge/Info/TabInfo.vue'
import TabInfoFile from '@/views/Oa/Knowledge/Info/TabInfoFile.vue'
import Authorize from '@/utils/authorize.js'
import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],  
    components:{
        TabInfo,
        TabInfoFile,
    },
    data(){
        return{
            knowledgeNumber:this.editId,   //知识编号
        }
    },
    computed:{
        ...mapState('Oa',['knowledgeInfo']),
        //添加Token - 重要
        GetHeader(){
            return {
                "Authorization":"Bearer "+ Authorize.getToken()
            }
        },
    },
    methods:{
        ...mapMutations('Oa',['SetKnowledgeInfo']),

        //获取信息
        GetDataInfo(){
            if(this.knowledgeNumber!=0){
                let param = {
                    knowledgeNumber:this.knowledgeNumber
                }
                this.$api.oa.GetKnowledgeAllInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.SetKnowledgeInfo(res.data.data)
                    }else{
                        this.btnClearData();
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存
        btnSaveInfo(){
            this.$api.oa.SaveKnowledgeAllInfo(this.knowledgeInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.knowledgeNumber === this.knowledgeNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.knowledgeNumber = prevInfo.knowledgeNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.knowledgeNumber === this.knowledgeNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.knowledgeNumber = prevInfo.knowledgeNumber
                this.GetDataInfo()
            }
        },

        //清空数据实体
        btnClearData(){
            let allInfo = {
                info:{
                    knowledgeNumber:'',
                    systemNumber:'',
                    typeId:0,
                    typeName:'',
                    title:'',
                    content:'',
                    isDel:0,
                },
                fileList:[],   //文件列表
                imgList:[],    //图片列表
            }
            this.SetKnowledgeInfo(allInfo);
        },


        //上传图片之前操作
        BeforeUpload(file){
            const fileType = file.type;
            const fileSize = file.size;
            if(fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'application/pdf' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                ElMessage.error('上传格式: jpeg | png | pdf | docx | xlsx')
                return false;
            }
            //文件大小
            if(fileSize/1024/1024 > 5){
                ElMessage.error('上传文件大小不能超过5Mb')
                return false;
            }

        },
        //上传完成（图片、文件）通用
        UploadSuccess(res,file){
            if(res.status === 200){
                const fileExt = res.data.slice((res.data.lastIndexOf(".") - 1 >>> 0) + 2); //文件类型
                let fileType = 1; //默认图片类型
                if(fileExt =="docx" || fileExt=="pdf" || fileExt == "xlsx"){
                    fileType = 2;  //文件类型
                }
                //创建新记录
                let newRow = {
                    id:0,
                    systemNumber:'',
                    knowledgeNumber:this.knowledgeInfo.info.knowledgeNumber,  //知识编号
                    title:file.name,
                    fileUrl:res.data,
                    fileType:fileType,  //类型
                    isDel:0,
                }
                this.knowledgeInfo.fileList.push(newRow);  
                ElMessage.success('上传成功');               
            }
        },
        //上传失败（图片、文件）通用
        UploadError(e){
            ElMessage.error(e)
        },


    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style scoped>
.newStyle {display: flex;justify-content: flex-end;}
</style>